@tailwind base;
@tailwind components;
@tailwind utilities;

:root,
.light,
.light-theme {
  --teal-1: #fbfefd;
  --teal-2: #f4fbf8;
  --teal-3: #daede5;
  --teal-4: #d6f0e5;
  --teal-5: #c4e8d9;
  --teal-6: #afddca;
  --teal-7: #91cdb5;
  --teal-8: #66b89a;
  --teal-9: #028c6a;
  --teal-10: #007e5d;
  --teal-11: #00805f;
  --teal-12: #203b31;

  --teal-a1: #00c08004;
  --teal-a2: #00a35d0b;
  --teal-a3: #00a7631a;
  --teal-a4: #00a25e29;
  --teal-a5: #019c5b3b;
  --teal-a6: #00935750;
  --teal-a7: #008c546e;
  --teal-a8: #00895799;
  --teal-a9: #008b69fd;
  --teal-a10: #007e5d;
  --teal-a11: #00805f;
  --teal-a12: #001f14df;

  --teal-contrast: #fff;
  --teal-surface: #f1faf6cc;
  --teal-indicator: #028c6a;
  --teal-track: #028c6a;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root,
    .light,
    .light-theme {
      --teal-1: oklch(99.4% 0.0035 168.1);
      --teal-2: oklch(98.1% 0.0081 168.1);
      --teal-3: oklch(96% 0.0205 168.1);
      --teal-4: oklch(93.4% 0.0316 168.1);
      --teal-5: oklch(90.2% 0.0426 168.1);
      --teal-6: oklch(85.9% 0.0541 168.1);
      --teal-7: oklch(80% 0.0696 168.1);
      --teal-8: oklch(72.1% 0.0924 168.1);
      --teal-9: oklch(56.9% 0.1146 168.1);
      --teal-10: oklch(52.4% 0.1146 168.1);
      --teal-11: oklch(52.9% 0.1146 168.1);
      --teal-12: oklch(32.6% 0.0381 168.1);

      --teal-a1: color(display-p3 0.0235 0.6745 0.349 / 0.012);
      --teal-a2: color(display-p3 0.0235 0.6118 0.3176 / 0.04);
      --teal-a3: color(display-p3 0.0118 0.5725 0.3137 / 0.091);
      --teal-a4: color(display-p3 0.0078 0.5608 0.3098 / 0.142);
      --teal-a5: color(display-p3 0.0039 0.5412 0.2902 / 0.204);
      --teal-a6: color(display-p3 0.0039 0.5098 0.2706 / 0.279);
      --teal-a7: color(display-p3 0.0039 0.4667 0.251 / 0.381);
      --teal-a8: color(display-p3 0 0.451 0.2588 / 0.522);
      --teal-a9: color(display-p3 0 0.3961 0.2431 / 0.761);
      --teal-a10: color(display-p3 0 0.349 0.2039 / 0.789);
      --teal-a11: color(display-p3 0 0.3569 0.2118 / 0.785);
      --teal-a12: color(display-p3 0 0.0941 0.0549 / 0.851);

      --teal-contrast: #fff;
      --teal-surface: color(display-p3 0.9529 0.9765 0.9647 / 0.8);
      --teal-indicator: oklch(56.9% 0.1146 168.1);
      --teal-track: oklch(56.9% 0.1146 168.1);
    }
  }
}

:root,
.light,
.light-theme {
  --gray-1: #fcfcfc;
  --gray-2: #f9f9f9;
  --gray-3: #f0f0f0;
  --gray-4: #e8e8e8;
  --gray-5: #e1e1e1;
  --gray-6: #d9d9d9;
  --gray-7: #cfcfcf;
  --gray-8: #bbb;
  --gray-9: #8d8d8d;
  --gray-10: #838383;
  --gray-11: #646464;
  --gray-12: #202020;

  --gray-a1: #00000003;
  --gray-a2: #00000006;
  --gray-a3: #0000000f;
  --gray-a4: #00000017;
  --gray-a5: #0000001e;
  --gray-a6: #00000026;
  --gray-a7: #00000030;
  --gray-a8: #00000044;
  --gray-a9: #00000072;
  --gray-a10: #0000007c;
  --gray-a11: #0000009b;
  --gray-a12: #000000df;

  --gray-contrast: #ffffff;
  --gray-surface: #ffffffcc;
  --gray-indicator: #8d8d8d;
  --gray-track: #8d8d8d;
}

@supports (color: color(display-p3 1 1 1)) {
  @media (color-gamut: p3) {
    :root,
    .light,
    .light-theme {
      --gray-1: oklch(99.3% 0 none);
      --gray-2: oklch(98.3% 0 none);
      --gray-3: oklch(95.6% 0 none);
      --gray-4: oklch(93.2% 0 none);
      --gray-5: oklch(90.9% 0 none);
      --gray-6: oklch(88.6% 0 none);
      --gray-7: oklch(85.3% 0 none);
      --gray-8: oklch(79.3% 0 none);
      --gray-9: oklch(64.4% 0 none);
      --gray-10: oklch(60.9% 0 none);
      --gray-11: oklch(50.2% 0 none);
      --gray-12: oklch(24.4% 0 none);

      --gray-a1: color(display-p3 0 0 0 / 0.0118);
      --gray-a2: color(display-p3 0 0 0 / 0.0235);
      --gray-a3: color(display-p3 0 0 0 / 0.0588);
      --gray-a4: color(display-p3 0 0 0 / 0.0902);
      --gray-a5: color(display-p3 0 0 0 / 0.1176);
      --gray-a6: color(display-p3 0 0 0 / 0.149);
      --gray-a7: color(display-p3 0 0 0 / 0.1882);
      --gray-a8: color(display-p3 0 0 0 / 0.2667);
      --gray-a9: color(display-p3 0 0 0 / 0.4471);
      --gray-a10: color(display-p3 0 0 0 / 0.4863);
      --gray-a11: color(display-p3 0 0 0 / 0.6078);
      --gray-a12: color(display-p3 0 0 0 / 0.8745);

      --gray-contrast: #ffffff;
      --gray-surface: color(display-p3 1 1 1 / 80%);
      --gray-indicator: oklch(64.4% 0 none);
      --gray-track: oklch(64.4% 0 none);
    }
  }
}

body {
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
}

.grecaptcha-badge {
  visibility: hidden;
}

.bg-stripe-gradient {
  background: repeating-linear-gradient(
    -55deg,
    #1f8269,
    #1f8269 8px,
    #34a387 8px,
    #34a387 13px
  );
}

.non-draggale {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.spin-wheel-animation {
  transform: rotate(var(--start-rotation-degrees));
}

.spin-wheel-animation.started-spinning {
  animation: spin-wheel var(--start-spinning-time)
      cubic-bezier(0.71, var(--spin-wheel-cb-arg), 0.96, 0.9) 0s 1 normal
      forwards running,
    continueSpin-wheel var(--continue-spinning-time) linear
      var(--start-spinning-time) 1 normal forwards running,
    stopSpin-wheel var(--stop-spinning-time) cubic-bezier(0, 0, 0.35, 1.02)
      var(--stop-spin-time-duration) 1 normal forwards running;
}

@keyframes spin-wheel {
  from {
    transform: rotate(var(--start-rotation-degrees));
  }
  to {
    transform: rotate(var(--start-rotation-degree-complete));
  }
}
@keyframes continueSpin-wheel {
  from {
    transform: rotate(var(--start-rotation-degrees));
  }
  to {
    transform: rotate(var(--start-rotation-degree-complete));
  }
}
@keyframes stopSpin-wheel {
  from {
    transform: rotate(var(--start-rotation-degrees));
  }
  to {
    transform: rotate(var(--final-rotation-degrees));
  }
}
